<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <PageHeader></PageHeader>
    <a-card style="width: 96%; margin: 24px; padding: 0">
      <a-form-model ref="refForm" class="ak-query-filter" :model="queryParams" :colon="false">
        <a-row :gutter="24">
          <a-col>
            <a-form-model-item>
              <a-button type="primary" @click="handleAdd">新增角色</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="false">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="员工姓名" prop="userName">
              <a-input v-model="queryParams.userName" style="width: 250px;" :maxLength="20"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="联系方式" prop="phone">
              <a-input v-model="queryParams.phone" style="width: 250px;" :maxLength="11"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="操作模块" prop="operatorModule" v-if="false">
              <a-select v-model="queryParams.operatorModule" style="width: 250px;">
                <a-select-option v-for="(item,index) in moduleList" :value="item.moduleId" :key="index">
                  {{item.moduleName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24" v-if="false">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="创建时间" prop="dateRange">
              <a-range-picker v-model="queryParams.dateRange" :locale="locale" format="YYYY-MM-DD"/>
            </a-form-model-item>
          </a-col>
          <a-col class="action-btn-main">
            <a-button type="primary" :ghost="true" @click="handleQuery">查询</a-button>
            <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-table :pagination='false'
             :columns="columns"
             :data-source="list"
             :scroll="{ x: '1000px' }"
             :rowKey="(record) => record.roleId"
             style="width:96%; margin: 0 auto"
    >
      <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)" class="action">编辑</a>
            <a @click="handleDelete(record)" class="action">删除</a>
          </template>
        </span>
    </a-table>
    <pagination :total="total" :pageNum.sync="queryParams.page" :pageSize.sync="queryParams.pageSize" @pagination="getList"/>
  </ak-container>
</template>

<script>

  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
  import * as RoleApi from '@/api/set/role.api'

  const columns = [
    {title: '角色名称', dataIndex: 'roleName', width: 110, align: 'center',},
    {title: '角色人数', dataIndex: 'employeesNum', width: 110, align: 'center',},
    {title: '操作', dataIndex: 'action', width: 150, align: 'center', fixed: 'right', scopedSlots: {customRender: 'action'}},
  ]

  export default {
    name: 'RoleList',
    components: {},
    dictTypes: [
    ],
    data() {
      return {
        locale,
        loading: false,
        queryParams: {
          // userName: null,
          // phone: null,
          // operatorModule: null,
          // dateRange: [], //['2022-01-09','2022-11-03'],
          // operatorStart: null,
          // operatorEnd: null,
          page: 1,
          pageSize: 10,
        },
        moduleList: [],

        columns: columns,
        list: [],
        total: 0,
      };
    },
    created() {
      this.getInit();
      this.getList();
    },
    methods: {
      getInit() {
      },

      // 列表API获取
      getList() {
        // this.queryParams.createDateBegin = this.queryParams.dateRange[0] || null
        // this.queryParams.createDateEnd = this.queryParams.dateRange[1] || null
        RoleApi.search(this.queryParams).then(data => {
          this.list = data.list || []
          this.total = data.total || 0
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },

      /**
       * 搜索查询
       */
      handleQuery() {
        this.loading = true
        this.queryParams.page = 1;
        this.getList()
      },

      handleReset() {
        this.$refs['refForm'].resetFields()
      },

      /**
       * 新增 mode=[1(新增）|2（编辑）|3（详情）]
       */
      handleAdd() {
        this.$router.push({name: 'RoleAdd', query: {mode: '1', id: null}})
      },
      handleEdit(record) {
        this.$router.push({name: 'RoleEdit', query: {mode: '2', id: record.roleId}})
      },
      // 删除
      handleDelete(record) {
        this.$modal.confirm(`删除会关联该角色的员工将取消关联，请注意！`, '是否删除该角色？', {iconType: 'exclamation-circle'}).then(() => {
        // this.$modal.confirm(`确定要删除${record.roleName}的记录吗？`).then(() => {
          RoleApi.del(record.roleId).then(data => {
            this.$modal.msg("删除成功")
            this.getList();
          }).catch(err => {
            console.log(err);
          })
        })
      },

    }
  }
</script>
<style lang="less" scoped>
  /deep/ .ant-card-body {
    padding: 18px 18px 8px 18px;
  }

  /deep/ .ant-col-xl-8 {
    padding-left: 0 !important;
  }
</style>
